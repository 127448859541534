import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For, If} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash-es'
import {media} from '../../../utils'
import {tagsMap} from '../../../tags'
import {storeIcons} from '../../../stores'
import Typography from '../../atoms/Typography'
import BaseLink from '../../atoms/BaseLink'
import ReferenceSlider from './ReferenceSlider'


const StyledReferenceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 6.125rem;

  ${media.down('mobile')(css`
    margin: 6rem 1.5rem 3.5rem;
    order: 0;
  `)}
`

const StyledTitle = styled(Typography)`
  grid-column: 1 / 2;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    order: 0;
  `)}
`

const StyledProvidersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-row: 2 / 3;
  grid-column: 1 / 2;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    flex-wrap: wrap;
    margin-top: 3.125rem;
  `)}
`

const StyledProviderContainer = styled.div`
  display: flex;
  margin-right: auto;

  ${media.down('mobile')(css`
    width: 50%;
  `)}
`

const StyledProvider = styled(BaseLink)`
  margin: 1.875rem 1.875rem 0 0;
`

const StyledSubtitle = styled(Typography)`
  grid-column: 1 / 2;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    order: 1;
    margin-top: 3.125rem;
  `)}
`

const StyledDescription = styled(Typography)`
  grid-column: 1 / 2;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    order: 3;
    margin-top: 3.125rem;
  `)}
`

const StyledKeyServicesContainer = styled.div`
  display: flex;
  grid-column: 1 / 2;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    flex-direction: column;
    order: 4;
    margin-top: 3.125rem;
  `)}
`

const StyledKeyServices = styled(Typography)`
  margin-right: 4rem;
`

const StyledKeyServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-left: 1px solid rgba(49, 49, 49, 0.1);
  padding-left: 1.25rem;

  ${media.down('mobile')(css`
    margin: 1.5rem 0;
  `)}
`

const StyledKeyService = styled(Typography)`
  padding: 0 0.75rem;
  height: calc(100%/3);

  ${media.down('mobile')(css`
    padding-bottom: 0.5rem;
    height: auto;
  `)}
`

const StyledProviderLabel = styled(Typography)`
  margin-left: 16px;
  position: relative;
  top: -5px;
  font-size: 15px;
`

const getUrlDomain = (url) => {
  try {
    const urlAddress = new URL(url)
    return (urlAddress.hostname || '').replace('www.', '')
  } catch {
    return url
  }
}

const DetailHero = ({title, providers, subTitle, description, keyServices, lang, sliderImages, ...props}) => {
  return (
    <StyledReferenceContainer {...props}>
      <StyledTitle type="h1">{title}</StyledTitle>
      <StyledProvidersContainer>
        <If condition={!isEmpty(providers)}>
          <For each="provider" of={providers} index="idx">
            <StyledProviderContainer key={idx}>
              <StyledProvider to={provider?.link}>
                <If condition={provider.provider === 'web'}>
                  {storeIcons[provider.provider].icon}
                  <StyledProviderLabel type="span">{getUrlDomain(provider.link)}</StyledProviderLabel>
                </If>
                <If condition={provider.provider !== 'web'}>
                  <Typography srOnly type="span">{storeIcons[provider.provider].label}</Typography>
                  {storeIcons[provider.provider].icon}
                </If>
              </StyledProvider>
            </StyledProviderContainer>
          </For>
        </If>
      </StyledProvidersContainer>
      <StyledSubtitle variant="h2" type="h5" thin>{subTitle}</StyledSubtitle>
      <StyledDescription>{description}</StyledDescription>
      <StyledKeyServicesContainer>
        <StyledKeyServices variant="h3" type="span" bold>{keyServices.keyServicesText}</StyledKeyServices>
        <StyledKeyServiceContainer>
          <For each="keyService" of={keyServices.services} index="idx">
            <StyledKeyService key={idx}>{tagsMap[keyService.keyService][lang].label}</StyledKeyService>
          </For>
        </StyledKeyServiceContainer>
      </StyledKeyServicesContainer>
      <ReferenceSlider sliderImages={sliderImages} />
    </StyledReferenceContainer>
  )
}

DetailHero.propTypes = {
  title: PropTypes.string.isRequired,
  providers: PropTypes.array.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keyServices: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  sliderImages: PropTypes.array.isRequired,
}

export default DetailHero
